/**
 * Module dependencies.
 */

import { ReactElement } from "react";
import { getApplicationsSorted } from "src/core/utils/sort-applications";
import { media } from "src/styles/utils";
import { resolvePageUrl } from "src/core/utils/routes";
import { useRouter } from "next/router";
import { useTranslate } from "src/core/utils/translator";
import Type from 'src/components/core/typography/index';
import styled from "styled-components";
import useProductApplications from "src/api/app/products/use-product-applications";

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${media.min('xs')`
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
    column-gap: 8px;
  `}

  ${media.min('ms')`
    display: flex;
    flex-direction: column;
  `}
`;

/**
 * `Item` styled component.
 */

const Item = styled.a`
  color: inherit;
  cursor: pointer;
  line-height: 32px;
  text-decoration: none;
  width: fit-content;
`;

/**
 * `Applications` component.
 */

const Applications = (): ReactElement => {
  const { locale } = useTranslate();
  const router = useRouter();

  const {
    data,
    isSuccess
  } = useProductApplications();

  let sortedData = [];

  if (isSuccess) {
    sortedData = getApplicationsSorted(data);
  }

  if (!sortedData.length) {
    return null;
  }

  const handleOnClick = (url: string) => () => {
    router.push(url);
  };

  return (
    <Wrapper>
      {sortedData.map((item) => (
        <Item key={item.slug} onClick={handleOnClick(`${resolvePageUrl('applications', locale)}/${item.slug}`)}>
          <Type.Small>
            {item.name}
          </Type.Small>
        </Item>
      ))}
    </Wrapper>
  );
};

/**
 * Export `Applications` component.
 */

export default Applications;
